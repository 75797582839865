import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element';
import VueTailwind from 'vue-tailwind';
import {
    TInput,
    TButton,
    TModal,
    TDatepicker,
    TDropdown,
} from 'vue-tailwind/dist/components';

import English from 'vue-tailwind/dist/l10n/index';
import Italian from 'vue-tailwind/dist/l10n/it';
import Spanish from 'vue-tailwind/dist/l10n/es';
import German from 'vue-tailwind/dist/l10n/de';
import French from 'vue-tailwind/dist/l10n/fr';
import translation from '@/mixins/translation.js';

import mdiVue from 'mdi-vue/v2'
import {
    mdiHumanMale,
    mdiMapMarker,
    mdiMapMarkerCircle,
    mdiMinusCircle,
    mdiPlusCircle,
} from '@mdi/js';

const icons = {
    mdiHumanMale,
    mdiMapMarker,
    mdiMapMarkerCircle,
    mdiMinusCircle,
    mdiPlusCircle,
}

import '@/index.css';

const components = {
    TInput,
    TButton,
    TModal,
    TDatepicker,
    TDropdown,
}

const settings = {
    // ...,
    't-input': {
        component: TInput,
    },
    't-dropdown': {
        component: TDropdown,
        props: {
            fixedClasses: {
              button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
              wrapper: 'inline-flex flex-col',
              dropdownWrapper: 'relative z-10',
              dropdown: 'origin-top-left absolute left-0 rounded shadow mt-1',
              enterClass: 'opacity-0 scale-95',
              enterActiveClass: 'transition transform ease-out duration-100',
              enterToClass: 'opacity-100 scale-100',
              leaveClass: 'opacity-100 scale-100',
              leaveActiveClass: 'transition transform ease-in duration-75',
              leaveToClass: 'opacity-0 scale-95'
            },
            classes: {
              button: 'bg-blue-500 hover:bg-blue-600',
              dropdown: 'bg-white'
            },
            variants: {
              danger: {
                button: 'bg-red-500 hover:bg-red-600',
                dropdown: 'bg-red-50'
              }
            }
        }
    },
    't-datepicker': {
      component: TDatepicker,
      props: {
        // classes: {...},
        // fixedClasses: {...},
        locales: {
            en: English,
            it: Italian,
            de: German,
            fr: French,
            es: Spanish,
        },
        lang: 'en',
        classes: {
            wrapper: 'flex flex-col',
            dropdownWrapper: 'relative z-10',
            
            // Dropdown related classes
            dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
            enterClass: 'opacity-0 scale-95',
            enterActiveClass: 'transition transform ease-out duration-100',
            enterToClass: 'opacity-100 scale-100',
            leaveClass: 'opacity-100 scale-100',
            leaveActiveClass: 'transition transform ease-in duration-75',
            leaveToClass: 'opacity-0 scale-95',
            
            // Wrapper for inline calendar
            inlineWrapper: '',
            inlineViews: 'rounded bg-white border mt-1 inline-flex flex-col',
            
            // Text input related classes
            inputWrapper: '',
            input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
            clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
            clearButtonIcon: '',
            
            // Picker views
            viewGroup: '',
            view: '',
            
            // Navigator
            navigator: 'pt-2 px-3',
            navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
            navigatorViewButtonIcon: 'fill-current text-gray-400',
            navigatorViewButtonBackIcon: 'fill-current text-gray-400',

            navigatorViewButtonMonth: 'text-gray-700 font-semibold',
            navigatorViewButtonYear: 'text-gray-500 ml-1',
            navigatorViewButtonYearRange: 'text-gray-500 ml-1',
            navigatorLabel: 'py-1',
            navigatorLabelMonth: 'text-gray-700 font-semibold',
            navigatorLabelYear: 'text-gray-500 ml-1',
            navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
            navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
            navigatorPrevButtonIcon: 'text-gray-400',
            navigatorNextButtonIcon: 'text-gray-400',
            
            // Calendar View
            calendarWrapper: 'px-3 py-2',
            calendarHeaderWrapper: '',
            calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
            calendarDaysWrapper: '',
            calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
            
            // Day item
            otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
            emptyDay: '',
            inRangeFirstDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-l-full',
            inRangeLastDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-r-full',
            inRangeDay: 'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
            selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-blue-500 text-white disabled:opacity-50 disabled:cursor-not-allowed',
            activeDay: 'text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
            highlightedDay: 'text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
            day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
            today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500',
            
            // Months View
            monthWrapper: 'px-3 py-2',
            selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
            activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
            month: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
            
            // Years View
            yearWrapper: 'px-3 py-2',
            year: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
            selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
            activeYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
            
            // Time selector *Since 2.2*
            timepickerWrapper: 'flex items-center px-4 py-2 space-x-2',
            timepickerTimeWrapper: 'flex items-center space-x-2',
            timepickerTimeFieldsWrapper: 'bg-gray-100 rounded-md w-full text-right flex items-center border border-gray-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            timepickerOkButton: 'text-blue-600 text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded cursor-pointer',
            timepickerInput: 'text-center w-8 border-transparent bg-transparent p-0 h-6 text-sm transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded',
            timepickerTimeLabel: 'flex-grow text-sm text-gray-500',
            timepickerAmPmWrapper: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            timepickerAmPmWrapperChecked: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            timepickerAmPmWrapperDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
            timepickerAmPmWrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
            timepickerAmPmButton: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded shadow',
            timepickerAmPmButtonChecked: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-full bg-white rounded shadow',
            timepickerAmPmCheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
            timepickerAmPmUncheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',              
        }
      }    
    },
    't-button': {
        component: TButton,
        props: {
          fixedClasses: 'bg-gray-300 block px-4 py-2 transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
          classes: 'text-white border border-transparent shadow-sm rounded',
          variants: {
            error: 'text-white border border-transparent rounded shadow-sm',
            success: 'text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600',
            link: 'text-blue-500 underline hover:text-blue-600'
          }
        }
    },
    't-modal': {
        component: TModal,
        props: {
            fixedClasses: {
                overlay: 'z-999 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
                wrapper: 'relative mx-auto z-50 px-3 py-12',
                modal: 'overflow-visible relative rounded',
                body: 'p-3',
                header: 'border-b p-3 rounded-t',
                footer: ' p-3 rounded-b',
                close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50'
            },
            classes: {
                overlay: 'bg-black',
                wrapper: 'lg:w-1/2 md:w-1/2 xs:w-full',
                modal: 'bg-white shadow',
                body: 'p-3',
                header: 'border-gray-100',
                footer: 'bg-gray-100',
                close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
                closeIcon: 'fill-current h-4 w-4',
                overlayEnterClass: 'opacity-0',
                overlayEnterActiveClass: 'transition ease-out duration-100',
                overlayEnterToClass: 'opacity-100',
                overlayLeaveClass: 'opacity-100',
                overlayLeaveActiveClass: 'transition ease-in duration-75',
                overlayLeaveToClass: 'opacity-0',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            variants: {
                guests: {
                    wrapper: 'lg:w-1/4 md:w-1/4 xs:w-full',
                },
                dates: {
                    wrapper: 'lg:w-1/2 md:w-1/2 xs:w-full',
                }
            }
        }
    }
  }

Vue.use(VueTailwind, settings)
Vue.use(mdiVue, {
    icons: icons
})
Vue.mixin(translation);

Vue.use(vueCustomElement);
Vue.customElement('ciaobooking-widget', App);
Vue.config.productionTip = false;
