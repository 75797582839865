<template>
  <div id="app">
    <div class="container mx-auto bg-white px-4 py-2" :class="containerClass">
      <form @submit.prevent>
        <div class="grid" :class="gridClass">
          <t-dropdown v-if="isDropdown" class="col-span-2">
            <template
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <div class="grid grid-cols-2"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <div id="cb-start-date-box" :class="fieldsClass">
                  <div class="uppercase text-sm">
                    {{ $lang('start_date') }}
                  </div>
                  <div class="flex flex-row justify-start">
                    <div id="cb-start-date-number" class="basis-1/4 text-4xl mr-2 my-1">
                      {{ form.sd.format('DD') }}    
                    </div>
                    <div class="basis-3/4 flex flex-col my-1">
                      <div class="text-sm font-bold">
                        {{ form.sd.locale(language).format('MMMM') }}
                        {{ form.sd.format('YYYY') }}
                      </div>
                      <div class="text-xs">
                        {{ form.sd.locale(language).format('dddd') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="cb-end-date-box" :class="fieldsClass">
                  <div class="uppercase text-sm">
                    {{ $lang('end_date') }}
                  </div>
                  <div class="flex flex-row justify-start">
                    <div id="cb-start-date-number" class="basis-1/4 text-4xl mr-2 my-1">
                      {{ form.ed.format('DD') }}    
                    </div>
                    <div class="basis-3/4 flex flex-col my-1">
                      <div class="text-sm font-bold">
                        {{ form.ed.locale(language).format('MMMM') }}
                        {{ form.ed.format('YYYY') }}
                      </div>
                      <div class="text-xs">
                        {{ form.ed.locale(language).format('dddd') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div slot-scope="{ hide, blurHandler }">
              <t-datepicker
                v-model="range"
                :inline="true"
                :range="true"
                :months-per-view="2"
                :lang="language"
                :weekStart="1"
                :minDate="today"
                @blur="blurHandler"
              ></t-datepicker>
            </div>
          </t-dropdown>
          <div v-if="!isDropdown" id="cb-start-date-box" @click="openDates" :class="fieldsClass">
            <div class="uppercase text-sm">
              {{ $lang('start_date') }}
            </div>
            <div class="flex flex-row justify-start">
              <div id="cb-start-date-number" class="basis-1/4 text-4xl mr-2 my-1">
                {{ form.sd.format('DD') }}    
              </div>
              <div class="basis-3/4 flex flex-col my-1">
                <div class="text-sm font-bold">
                  {{ form.sd.locale(language).format('MMMM') }}
                  {{ form.sd.format('YYYY') }}
                </div>
                <div class="text-xs">
                  {{ form.sd.locale(language).format('dddd') }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isDropdown" id="cb-end-date-box" @click="openDates" :class="fieldsClass">
            <div class="uppercase text-sm">
              {{ $lang('end_date') }}
            </div>
            <div class="flex flex-row justify-start">
              <div id="cb-start-date-number" class="basis-1/4 text-4xl mr-2 my-1">
                {{ form.ed.format('DD') }}    
              </div>
              <div class="basis-3/4 flex flex-col my-1">
                <div class="text-sm font-bold">
                  {{ form.ed.locale(language).format('MMMM') }}
                  {{ form.ed.format('YYYY') }}
                </div>
                <div class="text-xs">
                  {{ form.ed.locale(language).format('dddd') }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="!expandedOccupation" @click="openGuests" :class="fieldsClass">
            <div class="uppercase text-sm guests-label">
              {{ $lang('guests') }}
            </div>
            <div class="flex flex-row">
              <div id="cb-guests-number" class="basis-1/4 text-4xl mr-2 my-1">
                {{ guests }}
              </div>
              <!-- <div class="basis-3/4 my-2 ">
                <mdicon name="human-male" :width="30" :height="30" />
              </div> -->
            </div>
          </div>
          <div v-if="expandedOccupation" :class="fieldsClass">
            <div class="uppercase text-sm guests-label">
              {{ $lang('adults') }}
            </div>
            <div id="cb-guests-number" class="flex flex-row basis-4/4 text-4xl mr-2 my-1">
            <button @click="subAdults">
                <mdicon name="minus-circle" :width="40" :height="40" class="cb-primary-text" />
              </button>
              <!-- <t-button @click="subAdults" class="w-10 h-10 text-center flex items-center justify-center rounded-full cb-primary-bg">-</t-button> -->
              <span class="w-10 h-10 text-center flex items-center justify-center">{{form.ad}}</span>
              <button @click="addAdults">
                <mdicon name="plus-circle" :width="40" :height="40" class="cb-primary-text" />
              </button>
              <!-- <t-button @click="addAdults" class="w-10 h-10 text-center flex items-center justify-center rounded-full cb-primary-bg">+</t-button> -->
              <!-- <div class="basis-3/4 my-2 ">
                <mdicon name="human-male" :width="30" :height="30" />
              </div> -->
            </div>
          </div>
          <div v-if="expandedOccupation" :class="fieldsClass">
            <div class="uppercase text-sm guests-label">
              {{ $lang('children') }}
            </div>
            <div id="cb-guests-number" class="flex flex-row basis-4/4 text-4xl mr-2 my-1">
              <button @click="subChildren">
                <mdicon name="minus-circle" :width="40" :height="40" class="cb-primary-text" />
              </button>
              <!-- <t-button @click="subChildren" class="w-10 h-10 text-center flex items-center justify-center rounded-full cb-primary-bg">-</t-button> -->
              <span class="w-10 h-10 text-center flex items-center justify-center">{{form.ch}}</span>
              <button @click="addChildren">
                <mdicon name="plus-circle" :width="40" :height="40" class="cb-primary-text" />
              </button>
              <!-- <t-button @click="addChildren" class="w-10 h-10 text-center flex items-center justify-center rounded-full cb-primary-bg">+</t-button> -->
            </div>
          </div>

          <t-dropdown v-if="withDestinations && destinations && destinations.length" :class="fieldsClass">
            <template
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <div
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <div class="uppercase text-sm">
                  {{ $lang('destination') }}
                </div>
                <div class="flex flex-row basis-4/4 text-2xl mr-2 my-1">
                  <mdicon name="map-marker-circle" :width="40" :height="40" class="cb-primary-text" />
                  {{ destinationName }}
                </div>
              </div>
            </template>

            <div slot-scope="{ hide, blurHandler }">
              <button
                v-for="(item, i) in destinations"
                :key="i"
                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                role="menuitem"
                @click="selectDestination(item.id)"
                @blur="blurHandler"
              >
                {{item.name}}
              </button>
            </div>
          </t-dropdown>
          <div>
            <t-button id="cb-submit-button" class="h-full w-full uppercase" @click="submit">{{ alternativeSubmit ? $lang('book_now') : $lang('submit') }}</t-button>
          </div>
        </div>
        
      </form>
      <t-modal v-model="isGuestsOpen" variant="guests">
        <div class="flex flex-row h-14 mb-2 justify-self-center border-b mb-4">
          <div class="w-1/2 ">
            <span class="h-10 flex items-center justify-start">{{ $lang('adults') }}</span>
          </div>
          <div class="flex w-1/2 justify-end">
            <t-button @click="subAdults" class="w-10 h-10 text-center font-extrabold flex items-center justify-center rounded-full cb-primary-bg">-</t-button>
            <span class="w-10 h-10 text-center font-extrabold flex items-center justify-center">{{form.ad}}</span>
            <t-button @click="addAdults" class="w-10 h-10 text-center font-extrabold flex items-center justify-center rounded-full cb-primary-bg">+</t-button>
          </div>
        </div>
        <div v-if="withChildren" class="flex flex-row h-14 mb-2">
          <div class="w-1/2 justify-self-center items-center">
            <span class="h-10 flex items-center justify-start">{{ $lang('children') }}</span>
          </div>
          <div class="flex w-1/2 justify-end">
            <t-button @click="subChildren" class="w-10 h-10 text-center font-extrabold flex items-center justify-center rounded-full cb-primary-bg">-</t-button>
            <span class="w-10 h-10 text-center font-extrabold flex items-center justify-center">{{form.ch}}</span>
            <t-button @click="addChildren" class="w-10 h-10 text-center font-extrabold flex items-center justify-center rounded-full cb-primary-bg">+</t-button>
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button type="button" class="cb-primary-bg" @click="isGuestsOpen = false">
              {{ $lang('ok') }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <t-modal
        v-model="isDatesOpen"
        type="confirm"
        variant="dates"
      >
        <div class="">
          <t-datepicker
            v-model="range"
            :inline="true"
            :range="true"
            :months-per-view="2"
            :lang="language"
            :weekStart="1"
            :minDate="today"
          ></t-datepicker>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button class="cb-primary-bg" @click="isDatesOpen = false">
              {{ $lang('ok') }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <component :is="'style'">
        :root {
          --primary: {{ primaryColor }};
          --blue-600: {{ primaryColor }};
          --blue-200: {{ primaryColor.slice(0, 7) }}60;
          --primary-light: {{ primaryColor.slice(0, 7) }}60;
          --secondary: {{ secondaryColor }};
          --font: {{ fontFamily }};
        }
      </component>
    </div>
  </div>
</template>

<script>
const apiUrl = 'https://api.ciaobooking.com/api/3.0';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';

export default {
  name: "App",
  props: {
    host: {
      type: String,
      required: false,
      default: "",
    },
    propertyId: {
      type: Number,
      required: false,
    },
    primaryColor: {
      type: String,
      default: "#424242"
    },
    secondaryColor: {
      type: String,
      default: "#a9ddd6"
    },
    newPage: {
      type: Boolean,
      default: false
    },
    legacy: {
      type: Boolean,
      default: false
    },
    maxGuests: {
      type: Number,
      default: 999
    },
    bookingNetworkId: {
      type: Number,
      default: null,
    },
    withChildren: {
      type: Boolean,
      default: false,
    },
    withShadow: {
      type: Boolean,
      default: false,
    },
    withBoxBorder: {
      type: Boolean,
      default: false,
    },
    withDestinations: {
      type: Boolean,
      default: false
    },
    expandedOccupation: {
      type: Boolean,
      default: false,
    },
    alternativeSubmit: {
      type: Boolean,
      default: false,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: 'Montserrat'
    }
  },
  data() {
    return {
      form: {
        sd: null,
        ed: null,
        ad: 2,
        ch: null,
        dst: null,
      },
      range: [],
      masks: {
        input: 'DD/MM/YYYY',
      },
      today: new Date(),
      isDatesOpen: false,
      isGuestsOpen: false,
      isOpenSelectDestination: false,
      language: 'en',
      today: dayjs().format('YYYY-MM-DD'),
      destinations: [],
    };
  },
  beforeMount() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.form.sd = dayjs();
    this.form.ed = dayjs().add(1, 'day');
    this.language = navigator.language.slice(0, 2);
    this.getDestinations();
  },
  methods: {
    submit() {
      let link;

      const startDate = this.form.sd.format('YYYY-MM-DD');
      const endDate = this.form.ed.format('YYYY-MM-DD');

      if (this.legacy) {
        let octorateHost = 'https://www.octorate.com/octobook/site/reservation/result.xhtml'
        link = `${octorateHost}?codice=${this.propertyId}&checkin=${startDate}&checkout=${endDate}&pax=${this.form.ad}`;
      } else {
        if (this.propertyId) {
          link = `${this.host}/properties/${this.propertyId}?sd=${startDate}&ed=${endDate}&ad=${this.form.ad}&ch=${this.form.ch ?? 0}`;
        } else {
          link = `${this.host}/properties?sd=${startDate}&ed=${endDate}&ad=${this.form.ad}&ch=${this.form.ch ?? 0}`;
        }
      }

      if (this.form.dst) {
        link += `&dst=${this.form.dst ?? ''}`;
      }

      if (this.newPage) {
        window.open(link);
      } else {
        window.location.assign(link);
      }
    },
    openDates() {
      this.isDatesOpen = true;
    },
    openGuests() {
      this.isGuestsOpen = true;
    },
    addAdults() {
      if (this.guests >= this.maxGuests) {
        return;
      }

      this.form.ad +=1;
    },
    subAdults() {
      if (this.form.ad === 0) {
        return;
      }

      this.form.ad -=1;
    },
    addChildren() {
      if (this.guests >= this.maxGuests) {
        return;
      }

      this.form.ch +=1;
    },
    subChildren() {
      if (this.form.ch == 0) {
        return;
      }
      
      this.form.ch -=1;
    },
    async getDestinations() {
      if (!this.withDestinations || !this.bookingNetworkId) {
        return;
      }

      let response = await fetch(`${apiUrl}/booking-network-destinations/${this.bookingNetworkId}`);

      response = await response.json();
      this.destinations = response.data;
    },
    selectDestination(id) {
      this.form.dst = id;
      this.isOpenSelectDestination = false;
    }
  },
  computed: {
    borderRadius() {
      return {
        left: this.radius,
        right: this.radius,
      };
    },
    layout() {
      return this.$screens(
        {
          default: {
            columns: 1,
            rows: 1,
            isExpanded: true,
          },
          lg: {
            columns: 2,
            rows: 1,
            isExpanded: false,
          },
        },
      );
    },
    guests() {
      return this.form.ad + this.form.ch;
    },
    containerClass() {
      let result = '';

      if (this.withShadow) {
        result += ' shadow-md';
      }

      return result;
    },
    fieldsClass() {
      let result = '';
      if (this.withBoxBorder) {
        result += ' box-border'
      }
    },
    destinationName() {
      let destination = this.destinations.find(item => item.id === this.form.dst);

      if (!destination) {
        return '';
      }

      return destination.name;
    },
    gridClass() {
      if (!this.expandedOccupation && !this.withDestinations) {
        return 'lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-2';  
      }

      if (this.expandedOccupation && this.withDestinations) {
        return 'lg:grid-cols-6 md:grid-cols-6 xs:grid-cols-2';
      }

      return 'lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-2';
    }
  },
  watch: {
    range(val) {
      if (!val) {
        return;
      }

      if (!val || val.length !== 2) {
        return;
      }

      this.form.sd = dayjs(val[0]);
      this.form.ed = dayjs(val[1]);
    }
  }
};
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
#app {
  all: initial;
  font-family: var(--font);
}
#cb-start-date-number {
  color: var(--primary);
}
#cb-end-date-number {
  color: var(--primary);
}
#cb-guests-number {
  color: var(--primary);
}
#cb-submit-button {
  background-color: var(--primary);
}
#cb-submit-button:hover {
  background-color: var(--primary-light);
}
.cb-primary-bg {
  background-color: var(--primary) !important;
}
.cb-primary-text {
  color: var(--primary) !important;
}
.z-999 {
  z-index: 999;
}
.box-border {
  border: 1px solid var(--primary) !important;
  padding: 3px;
  margin: 3px;
  border-radius: 4px;
}
.flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
</style>